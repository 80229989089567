@import './variables';

.title {
    text-transform: uppercase;
}

.banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    picture {
        width: 100%;
    }

    &-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .link {
        font-weight: 500;
        text-transform: uppercase;
        color: $white;
        display: inline-block;
        border: 1px solid $white;
        text-align: center;
        background-color: $white;
        color: $black;
    }
}

.text-section {
    border-bottom: 1px solid $border;
}

.card {
    &-image {
        display: block;

        img {
            width: 100%;
        }
    }

    &-info {
        a {
            text-decoration: underline;
        }
    }

    .link {
        font-family: 'Lato';
        text-transform: uppercase;
        text-decoration: underline;
        color: $grey;
    }
}

.carousel {
    &--products {
        background-color: $black;
        color: $white;

        .carousel {
            &-item {
                display: flex !important;
            }

            &-card {
                display: flex;
                flex-direction: column;
                min-height: 1px;
                width: 100%;

                &__info {
                    display: flex;
                    flex-direction: column;
                    min-height: 1px;
                    width: 100%;
                    -webkit-flex-grow: 1;
                    flex-grow: 1;
                    -ms-flex-negative: 0;
                    -webkit-flex-shrink: 0;
                    flex-shrink: 0;
                    -webkit-flex-basis: auto;
                    flex-basis: auto;

                    p {
                        -webkit-flex-grow: 1;
                        flex-grow: 1;
                        -ms-flex-negative: 0;
                        -webkit-flex-shrink: 0;
                        flex-shrink: 0;
                        -webkit-flex-basis: auto;
                        flex-basis: auto;
                    }
                }
            }
        }

        .title {
            color: $white;
        }

        .category {
            font-size: 17px;
        }
    
        .link {
            font-size: 15px;
            font-weight: 500;
            color: $white;
            text-decoration: underline;
        }
    }
}

.testimonials-slide {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .03em;
    line-height: 1.5;
}

@media (max-width: $mobile) {
    .banner {
        .link {
            font-size: 21px;
            font-weight: 500;
            line-height: 1.3;
            max-width: 262px;
            padding: 10px 22px 9px;
            box-sizing: border-box;
        }
    }

    .text-section {
        width: calc(100% - 64px);
        margin: 0 auto 50px;
        padding: 43px 0 40px;

        &--about {
            margin-bottom: 0;
        }
    }

    .cards {
        &-container {
            padding: 0 30px;
        }

        &--contact {
            .card {
                margin-bottom: 34px;
                padding-bottom: 20px;

                &:first-child {
                    border-bottom: 1px solid $border;
                }

                .title {
                    font-size: 18px;
                    margin-bottom: 44px;
                }

                p {
                    line-height: 1.6;

                    + .title {
                        margin-top: 36px;
                        padding-top: 33px;
                        border-top: 1px solid $border;
                    }
                }
            }
        }
    }

    .card {
        margin-bottom: 58px;

        &-image {
            margin-bottom: 34px;
        }

        .title {
            margin-bottom: 30px;
        }
    }

    .carousel {
        &-container {
            padding: 44px 30px 33px;
        }

        &-image {
            display: block;
            margin-bottom: 24px;
        }

        &-item {
            margin-bottom: 30px;
        }

        p {
            font-size: 15px;
        }

        .title,
        .category {
            text-align: center;
        }

        .category {
            margin-bottom: 38px;
        }

        .slick-arrow {
            position: absolute;
            bottom: -5px;
        }

        .slick {
            &-next {
                right: 0;
            }

            &-prev {
                left: 0;
            }
        }

        &--products {
            .slick-dots {
                .slick-active {
                    button {
                        background-color: $white;
                    }
                }
            }

            .slick-arrow {
                svg {
                    stroke: $white;
                }
            }
        }
    }

    // testimonials 

    .testimonials {
        padding: 33px 30px 78px;

        &-slide {
            margin-bottom: 72px;
        }

        .title {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 30px;
        }
    }
}

@media (min-width: $desktop) {
    .banner {
        .link {
            font-size: 1.73vw;
            padding: 2.049vw 2.601vw;
            transition: color .3s, background-color .3s;
        }
    }

    .text-section {
        max-width: 1440px;
        margin: 0 auto;
        padding: 9vw 0;

        h2 {
            font-size: 22px;
        }

        .content {
            max-width: 780px;
            margin: 0 auto;
        }
    }

    .cards {
        &-container {
            display: flex;
            margin: 0 auto 9vw;
            max-width: 1200px;
        }

        &--contact {
            .card {
                &-info {
                    padding-bottom: 0;

                    .title {
                        margin-bottom: 22px;
                    }

                    p {
                        line-height: 1.5;
                        letter-spacing: 0;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        + .title {
                            margin-top: 70px;
                        }
                    }
                }
            }
        }
    }

    .hover-img {
        font-size: 0;
        overflow: hidden;
        display: block;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: hsla(0, 0%, 100%, 0.3);
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
        }

        &:hover {
            &:before {
               opacity: 1;
               visibility: visible;
            }
        }
    }

    .card {
        padding: 0 25px;
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;

        &:first-child {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: $border;
            }
        }

        &-info {
            order: -1;
            padding: 4vw 4.5vw 5vw;

            a {
                &:hover {
                    text-decoration: none;
                }
            }

            p {
                margin-bottom: 35px;
            }
        }

        .title {
            font-size: 18px;
        }

        .link {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .carousel {
        &-container {
            max-width: 1440px;
            margin: 0 auto;
            padding: 83px 40px 54px;
        }

        &-body {
            margin: 0 -20px;
            position: relative;
        }

        &-item {
            padding: 0 20px !important;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 1px;
                height: 100%;
                background-color: $grey3;
            }
        }

        &-image {
            display: block;
            margin-bottom: 28px;
        }

        &--products {
            .slick-list {
                margin-right: 1px;
            }
    
            .slick-arrow {
                position: absolute;
                top: 44%;
                transform: translateY(-50%);
                z-index: 1;
            }
    
            .slick-next {
                right: 57px;
            }
    
            .slick-prev {
                left: 57px;
            }
        }

        .category {
            margin-bottom: 27px;
        }

        p {
            font-size: 14px;
            padding-right: 30px;
            margin-bottom: 34px;
        }

        .link {
            &:hover {
                text-decoration: none;
            }
        }
    }

    // testimonials

    .testimonials {
        max-width: 775px;
        margin: 0 auto;
        padding: 6vw 0 7vw;
    }

    .carousel--testimonials {
        margin-top: 5.6vw;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: .03em;
        line-height: 1.5;
    }

    .testimonials-slide {
        margin-bottom: 5.5vw;
    }
}

@media (min-width: $desktop-xl) {
    .banner {
        .link {
            font-size: 25px;
            padding: 29px 37px;
        }
    }

    .text-section {
        padding: 130px 0 101px;

        &--about {
            padding: 93px 0 65px;
        }
    }

    .cards {
        margin-bottom: 130px;

        &--contact {
            .card {
                &-info {
                    padding-left: 155px;
                }
            }
        }
    }

    .card {
        padding: 0 50px;

        &-info {
            padding: 53px 62px 73px;
        }
    }

    .carousel {
        &-body {
            margin: 0 -40px;
        }

        &-item {
            padding: 0 40px !important;
        }
    }

    // testimonials

    .testimonials {
        padding: 85px 0 97px;

        &-slide {
            margin-bottom: 70px;
        }
    }

    .carousel--testimonials {
        margin-top: 82px;
    }
}
