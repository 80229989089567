@import './variables';

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Bold.eot');
    src: url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato/Lato-Bold.woff') format('woff'),
        url('../fonts/Lato/Lato-Bold.ttf') format('truetype'),
        url('../fonts/Lato/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Medium.eot');
    src: url('../fonts/Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter/Inter-Medium.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Medium.woff') format('woff'),
        url('../fonts/Inter/Inter-Medium.ttf') format('truetype'),
        url('../fonts/Inter/Inter-Medium.svg#Inter-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Regular.eot');
    src: url('../fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter/Inter-Regular.ttf') format('truetype'),
        url('../fonts/Inter/Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

h1,
h2,
h3,
h4 {
    font-family: 'Lato';
    color: $black;
    font-weight: 600;
}

h2 {
    letter-spacing: .04em;
    line-height: 1.2;
}

h3 {
    font-size: 18px;
    line-height: 1.3;
}

@media (max-width: $mobile) {
    h2 {
        font-size: 20px;
        margin-bottom: 22px;
    }

    h3 {
        margin-bottom: 33px;
    }
}

@media (min-width: $desktop) {
    h2 {
        font-size: 18px;
        margin-bottom: 31px;
    }

    h3 {
        margin-bottom: 42px;
    }
}