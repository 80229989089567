@import './variables';

.brand-images {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-between;
}

.brand {
    &-head {
        display: flex;
        flex-flow: wrap;
    }
}

@media (max-width: $mobile) {
    .brands {
        &-intro {
            .text-section {
                margin-bottom: 0;
            }
        }
    }

    .brand {
        &-head {
            margin-bottom: 35px;
            align-items: center;
        }

        &-block {
            padding: 50px 30px 0;

            &:last-child {
                padding-bottom: 43px;

                .brand-images {
                    border-bottom: 0;
                }
            }
        }

        &-logo {
            max-width: 178px;
            margin-bottom: 20px;
        }

        &-title {
            width: calc(100% - 200px);
            margin-left: auto;

            .title {
                font-size: 18px;
            }
        }

        &-info {
            width: 100%;

            p {
                clear: both;
            }
        }

        &-images {
            padding-bottom: 25px;
            border-bottom: 1px solid $border;

            img {
                width: 100%;
            }

            .img {
                width: calc(50% - 15px);
                margin-bottom: 20px;
            }
        }
    }
}

@media (min-width: $desktop) {
    .brands {
        &-intro {
            padding-bottom: 70px;
            border-bottom: 1px solid $border;

            .container {
                max-width: 1120px;
            }

            .text-section {
                border-bottom: 0;
                padding: 95px 0 26px;
            }
        }

        &-list {
            display: flex;
            flex-flow: wrap;
            align-items: center;

            li {
                width: 25%;
                padding: 50px;
                text-align: center;
            }
        }
    }

    .brand-block {
        max-width: 1024px;
        margin: 0 auto;
        padding: 95px 20px 67px;
        border-bottom: 1px solid $border;

        &:last-child {
            padding-bottom: 166px;
        }
    }

    .brand-head {
        margin-bottom: 75px;
        align-items: center;
    }

    .brand {
        &-logo {
            text-align: center;
            width: 53%;
        }

        &-info {
            width: 47%;
        }

        &-images {
            margin: 0 -20px;

            .img {
                width: 25%;
                padding: 0 20px;
                margin-bottom: 30px;
            }
        }
    }
}

@media (min-width: $desktop-xl) {
	
}