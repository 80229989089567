@import './variables';

.footer {
    font-size: 13px;
    background-color: $black;
    color: $white;

    a {
        color: $white;
        text-decoration: underline;
    }
}

.terms {
    a {
        display: inline-block;
        margin: 0 5px;
    }
}

@media (max-width: $tab - 1) {
    .footer {
        line-height: 1.5;

        &-container {
            padding: 41px 48px 40px;
            text-align: center;
        }

        .logo {
            margin-bottom: 28px;
            display: block;
        }

        .copyright {
            line-height: 1.7;
            margin-bottom: 30px;
        }
    }
}

@media (min-width: $tab) {
    .footer {
        &-container {
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: space-between;
            max-width: 1440px;
            padding: 45px 40px 37px;
            margin: 0 auto;
        }

        .copyright {
            order: -1;
            width: 40%;
        }

        .terms {
            width: 40%;
            text-align: right;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}