@import './variables';

body {
    font-size: 16px;
    font-family: 'Inter';
    color: $grey;
}

a {
    color: $black;
    text-decoration: none;
}

img {
    max-width: 100%;
}

picture {
    font-size: 0;
}

p {
    line-height: 1.4;
    letter-spacing: 0.4px;
    margin-bottom: 25px;
}

.a-center {
    text-align: center;
}

.a-left {
    text-align: left;
}

.a-right {
    text-align: right;
}

.std {
    .container {
        max-width: 785px;
        margin: 0 auto;
    }

    a {
        text-decoration: underline;
    }
    
    p {
        line-height: 1.5;
    }

    ol,
    ul {
        margin: 15px 0;

        li {
            margin-bottom: 15px;
            line-height: 1.4;
        }
    }

    ul {
        list-style-type: square;
        padding-left: 20px;
    }

    ol {
        counter-reset: newCounter;

        > li {
            &:before {
                counter-increment: newCounter;
                content: counters(newCounter,".") ".";
            }
        }
    }

    table {
        width: 100%;
        border: 1px solid #EEEEEE;
        margin-bottom: 30px;
        table-layout: fixed;
        text-align: center;

        th,
        td {
            padding: 14.08px 8px;
            vertical-align: middle;
            font-size: 14px;
            line-height: 1.4;
        }

        th {
            background: #000;
            color: #fff;
        }

        tr {
            &:nth-of-type(even) {
                background-color: #eeeeee;
            }
        }
    }

    &.bb-grey {
        position: relative;

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            content:'';
            height: 1px;
            width: 100%;
            max-width: 1355px;
            margin: auto;
            background-color: $grey3;
            display: block;
        }
    }
}

.black-bg {
    background-color: $black;
    color: $white;

    .title,
    h2,
    h3,
    a {
        color: $white;
    }
}

@media (max-width: $mobile) {
    .desktop-only {
        display: none;
    }

    .container {
        padding: 0 20px;
    }

    .std {
        padding: 44px 0 12px;

        .container {
            padding: 0 30px;
        }

        img {
            margin: 16px 0 42px;
        }

        &:last-child {
            padding-bottom: 76px;
        }

        &.bb-grey {
            padding-bottom: 20px;
            
            &:after {
                margin: 0;
                left: 30px;
                width: calc(100% - 60px);
            }
        }

        table {
            display: block;
            
            thead {
                display: none;
            }

            tbody,
            tr,
            th,
            td {
                display: block;
            }
        }
    }
}

@media (min-width: $desktop) {

    .mobile-only {
        display: none;
    }

    .container {
        max-width: 1440px;
        margin: 0 auto;
    }

    .std {
        padding: 40px 20px 93px;

        h3 {
            margin-top: 45px;
        }

        p {
            margin-bottom: 22px;
        }

        img {
            margin: 45px 0 20px;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}