@import './variables';

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-track:before,
.slick-track:after {
	display: table;
	content: '';
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;
	min-height: 1px;
	padding: 0 1px;
}

.slick-slide img {
	display: block;
	width: 100%;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	
    &.slick-hidden,
    &.slick-disabled {
        display: none !important;
    }
}

.slick-dots {
	text-align: center;

	li {
		display: inline-block;
		margin: 0 5px;

		&.slick-active {
			button {
				background-color: $black;
			}
		}
	}

	button {
		font-size: 0;
		background-color: $grey4;
		border-radius: 50%;
		width: 7px;
		height: 7px;
		position: relative;
		outline: none;
		border: 0;
		cursor: pointer;

		&:before,
		&:after {
			position: absolute;
			left: 0;
			display: inline-block;
			width: 100%;
			height: .625rem;
			content: "";
		}

		&:after {
			bottom: -.3rem;
		}

		&:before {
			bottom: .3rem;
		}
	}
}

.carousel-nav {
	display: flex;
	flex-flow: wrap;
	align-items: center;
	justify-content: center;
	position: relative;

	.slick {
		&-prev {
			order: 1;
		}

		&-dots {
			order: 2;
		}

		&-next {
			order: 3;
		}
	}
}

@media (max-width: $mobile) {
}

@media (min-width: $desktop) {
	.carousel {
		&--products {
			.slick {
				&-arrow {
					height: 36px;
					width: 36px;
					background-color: $white;
					border-radius: 50%;
					border: 0;
					opacity: 0.45;
					transition: opacity .3s;
					cursor: pointer;
		
					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}

	.carousel-nav {
		.slick {
			&-dots {
				margin: 0 6.2vw 10px;
			}

			&-arrow {
				padding: 15px;
			}
		}
	}
    
}

@media (min-width: $desktop-xl) {
	.carousel-nav {
		.slick {
			&-dots {
				margin: 0 76px 10px;
			}
		}
	}
}