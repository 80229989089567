@import './variables';

.header {
    &-top {
        border-top: 10px solid $black;
    }

    &-menu {
        a {
            text-decoration: none !important;
        }
    }
}

@media (max-width: $mobile) {
    body {
        &.nav-open {
            .header {
                &-navigation {
                    opacity: 1;
                    visibility: visible;
                }

                &-menu,
                &-navigation-help {
                    opacity: 1;
                    transform: none;
                }
            }
        }
    }

    .header {
        &-wrap {
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 23px 30px;
        }

        &-navigation {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index: 200;
            background-color: $black;
            overflow: auto;
            opacity: 0;
            visibility: hidden;
            padding-bottom: 50px;

            &-help {
                padding: 0 30px;
            }

            .header-wrap {
                margin-top: 10px;
            }
        }

        &-menu {
            padding: 47px 30px 70px;

            a,
            span {
                font-size: 24px;
                color: $white;
                display: block;
                padding: 29px 0;
                border-bottom: 1px solid $grey;
            }
        }

        &-menu,
        &-navigation-help {
            transition: opacity .4s ease-in-out,transform .4s ease-in-out;
            transform: translateY(10px);
            opacity: 0;
        }

        &-contacts {
            letter-spacing: .3px;
            color: $white;
            margin-bottom: 69px;

            a {
                color: $white;
                text-decoration: underline;
            }

            p {
                margin-bottom: 14px;
            }

            .tel {
                text-decoration: none;
            }
        }

        &-terms {
            font-size: 13px;

            a {
                color: $grey2;
                text-decoration: underline;
            }

            p {
                margin-bottom: 11px;
            }
        }
    }
}

@media (min-width: $desktop) {
    .header {
        text-align: center;

        &-top {
            padding: 38px 0 40px;
        }

        &-menu {
            max-width: 1440px;
            margin: 0 auto 10px;
            border-bottom: 1px solid #f4f4f4;
            overflow: hidden;

            a,
            span {
                font-size: 13px;
                font-family: 'Lato';
                text-transform: uppercase;
                letter-spacing: 0.31px;
                color: $black;
                display: block;
                padding: 10px 0 28px;
                position: relative;

                &:hover {
                    &:before {
                        opacity: 1;
                        transform: none;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                    background-color: $black;
                    opacity: 0;
                    transform: translateY(10px);
                    transition: opacity .3s, transform .3s;
                }
            }

            li {
                display: inline-block;
                vertical-align: middle;
                margin: 0 18px;

                &.active {
                    span {
                        &:before {
                            opacity: 1;
                            transform: none;
                        }
                    }
                }
            }
        }
    }
}